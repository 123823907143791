import ReactDOM from 'react-dom';
import DatePicker from "react-datepicker";
import usersStore from "../../store/users";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import {useEffect, useRef, useState} from "react";
import useOrder from "../../hooks/order.hook";
import usePosition from "../../hooks/position.hook";
import {DatepickerLoader} from "../ui/datepicker/datepickerLoader";


function ReadyTimePicker({orderId, positionId, callback, small = false, tooltip = ''}) {
	const {setReadyDate: setOrderReadyDate} = useOrder();
	const {setReadyDate: setPositionReadyDate} = usePosition();
	const [startDate, setStartDate] = useState(new Date());
	const [dateLoader, setDateLoader] = useState(false);
	const containerRef = useRef(null);


	// useEffect(() => {
	// 	if(!inCurrentDayFirstInBlockedMonth) return;
	// 	const monthWrapperElement = document.querySelector('[class*="style_caption_end"]')
	//
	// 	if (monthWrapperElement) {
	// 		// Создаем новый элемент для компонента
	// 		const container = document.createElement('div');
	// 		container.classList.add(s.blocked_month_wrapper);
	// 		containerRef.current = container;
	// 		monthWrapperElement.appendChild(container);
	// 		// Рендерим JSX-компонент в созданный элемент
	// 		ReactDOM.render(<MonthBlocked />, container);
	// 	}
	//
	// 	return () => {
	// 		if (containerRef.current) {
	// 			ReactDOM.unmountComponentAtNode(containerRef.current);
	// 			containerRef.current.remove();
	// 		}
	// 	};
	//
	// }, [inCurrentDayFirstInBlockedMonth]);


	useEffect(() => {
		const datePickerNode = document.querySelector(".react-datepicker");
		if (datePickerNode) {
			if (dateLoader) {
				const container = document.createElement('div');
				container.classList.add('react-datepicker_loader_container');
				containerRef.current = container;
				datePickerNode.appendChild(container);
				ReactDOM.render(<DatepickerLoader/>, container);
			} else if (!dateLoader) {
				if (containerRef.current) {
					ReactDOM.unmountComponentAtNode(containerRef.current);
					containerRef.current.remove();
				}
			}

			return () => {
				if (containerRef.current) {
					ReactDOM.unmountComponentAtNode(containerRef.current);
					containerRef.current.remove();
				}
			};
		}
	}, [dateLoader])

	if (!usersStore.isGodMod()) return null;

	const onDateChage = async (value) => {
		console.log(value)
		value = new Date(value);
		value.setMilliseconds(0);
		setDateLoader(true)
		setStartDate(value)
		if (orderId) {
			await setOrderReadyDate({
				date: value,
				orderId,
			});
		}
		if (positionId) {
			await setPositionReadyDate({
				date: value,
				positionId,
			});
		}

		callback && await callback();
		setDateLoader(false)
	};

	const getTimeClassName = (time) => {
		const datePickerTime = new Date(time).getTime()
		const currentTime = new Date().getTime()
		return (datePickerTime - currentTime) > 0 ? 'allow' : 'disable'
	}

	return (
		<div className="text-Regular(14_16) relative"
		     data-tooltip-id={"comment-info"}
		     data-tooltip-content={tooltip}
		     data-tooltip-place="top"
		>
			<DatePicker
				portalId="ready-picker-portal"
				selected={startDate}
				popperPlacement={'bottom-end'}
				minDate={new Date()}
				locale="ru"
				dateFormat="MMMM d, yyyy h:mm"
				timeCaption="Время"
				showTimeSelect
				timeClassName={getTimeClassName}
				onChange={onDateChage}
				shouldCloseOnSelect={false}
				customInput={
					<div
						className="ease-in-out flex-none duration-300 bg-[#fff] w-[5rem]flex justify-center p-[2px] hover:cursor-pointer hover:opacity-60">
						<i
							className={`${small ? 'text-Regular(14_16)' : 'text-Regular(24_30)'} icon-i-calendar  hover:cursor-pointer  text-Accent/Blue`}
						></i>
					</div>
				}
			></DatePicker>
		</div>


	);
}

export default ReadyTimePicker;
