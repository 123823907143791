function DefaultModal({ title, children }) {
	return (

		<div className="p-[30px] pt-[10px] w-[500px]">
			{title && <span className='block text-center text-Regular(48_52) text-Content/Dark mb-[5rem]'>{title}</span>}
			{children}
		</div>
	);
}

export default DefaultModal;