import { useEffect, useState } from "react";
import Input from "../../ui/input/input";
import Button from "../../ui/button/button";
import Modal from "../../modal/modal-mobile";
import { emmiter } from "../../../helpers/Emmiter";
import { getDeclination } from "../../../helpers/common";
import usePosition from "../../../hooks/position.hook";

function StampProgressModal() {
  const [modal, setModal] = useState(false);
  const [quantity, setQuantity] = useState(0);

  const { setPrintQuantity } = usePosition();

  useEffect(() => {
    emmiter.on("stampProgressModal", (data) => {
      setModal(data);
    });
    return () => emmiter.off("stampProgressModal");
  }, []);

  const onSubmit = () => {
    const { drawingId, positionStageId,getData } = modal;
    setPrintQuantity(positionStageId, quantity, drawingId);
    setModal(false);
    getData()
  };

  const remain = modal.quantity - modal.readyCount < 0 ? 0 :modal.quantity - modal.readyCount

  return (
    <Modal isOpen={modal} closeModal={() => setModal(false)}>
      <div className="w-[90vw] max-w-[76rem] min-h-[10rem] pt-[0rem]">
        <span className="block text-center text-Regular(38_42) text-Content/Dark mb-[2rem]">
          Прогресс печати
        </span>
        <div className="text-Regular(14_16) text-center text-Content/Middle">
          {modal.name}
        </div>
        <div className="text-Regular(16_20) text-center mt-[0.5rem]">
          {`${modal.readyCount || 0} ${getDeclination(modal.quantity, [
            "штук",
            "штук",
            "штук",
          ])} (осталось ${remain})`}
        </div>
        <div className="p-[2rem]">
          <div className="py-[3rem] px-[2rem] bg-Dominant/Dop ">
            <span className="text-Regular(16_18)">
              {modal.title} - <span className="font-medium">{modal.code}</span>{" "}
            </span>
            <div className="mt-[3.8rem]">
              <Input
                type="number"
                onInput={({ target: { value } }) => setQuantity(value)}
					 maxValue={modal.quantity}
                placeholder="Введите число"
                label="Сколько вы уже распечатали?"
              ></Input>
            </div>

            <div className="flex justify-center mt-[3.8rem]">
              <Button name="Сохранить" onPress={onSubmit}></Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default StampProgressModal;
