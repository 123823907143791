
import { useContext, useEffect, useState } from 'react';
import { AxiosContext } from '../context/request.context';
const qs = require('qs');


const query = qs.stringify({
	populate: {
		statuses: {
			sort: ['sort:asc']
		},
		suggests: true,
		m_role: true
	},
	//TODO STAGE HIDE FILTER
	filters: {
		isHideStage: {
			$eq: false
		}
	},
}, {
	encodeValuesOnly: true, // prettify URL
});


function useStages() {

	const { authRequest } = useContext(AxiosContext);
	const [stages, setStages] = useState([]);

	useEffect(() => {
		(async () => {
			const { data } = await authRequest({
				url: 'stages?' + query,
			})
			// setStages(data)

			const updatedData = data.filter(s => !s.isHideStage)
			setStages(updatedData)
			// TODO HIDE DESIGN
		})();
	}, [authRequest])


	return {
		stages
	};
}

export default useStages;