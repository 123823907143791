function ButtonRejectMobile({ name, onPress }) {
	return (
		<div
			onClick={onPress}
			className="hover:bg-Accent/Red_Middle hover:cursor-pointer duration-300 ease-in-out  py-[0.6rem] group px-[1.2rem]  font-medium rounded-[8px] bg-Accent/Red text-white text-Regular(14_16)">
			<span className="flex justify-center  items-center gap-[0.8rem]">
				<span>{name}</span>
				<i className="icon-times-circle text-Regular(18_24)"></i>
			</span>

		</div>
	);
}

export default ButtonRejectMobile;