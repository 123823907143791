import { useCallback, useContext, useEffect, useState } from "react";
import Authorization from "../authorization/authorization";
import { AuthContext } from "../../context/auth.context";
import Modal from "../modal/modal";
import ConfirmModal from "../modals/confirmModal/confirmModal";
import userStore from "../../store/users";
import { observer } from "mobx-react-lite";
import useStorage from "../../hooks/storage.hook";
import OutsideAlerter from "../outsideAlerter/outsideAlerter";


const User = ({ username, name, active = false, isOtherShowed }) => {
  return (
    <div
      className={`
				${
          !active ? "hover:bg-Dominant/Dop hover:cursor-pointer" : ""
        } group p-[1.2rem] ease-in-out duration-300 bg-[#fff] `}
    >
      <div className="flex gap-[1.2rem] items-start">
        <div
          className={`
					${
            active
              ? " relative after:w-[1.2rem] after:right-0 after:bottom-0  after:absolute after:h-[1.2rem] after:rounded-[50%] after:bg-Accent/Dark_Yellow"
              : ""
          }
					 flex items-center justify-center`}
        >
          <i
            className={`
						${active ? "text-Accent/Blue" : "text-Content/Light"} 
						 icon-user text-Regular(24_30)`}
          ></i>
        </div>
        <div className="flex flex-1 flex-col">
          <span className="text-Regular(14_16) text-Content/Dark mb-[2px]">
            {username}
          </span>
          <div className="flex justify-between text-Regular(12_14) gap-[4rem]">
            <span className={`${active ? "text-Accent/Dark_Yellow" : ""}`}>
              {name}
            </span>
            {active ? (
              <span className="text-Accent/Blue">Активен</span>
            ) : (
              <span className="group-hover:opacity-100 ease-in-out duration-300 opacity-0 block rounded-[8px] border border-Accent/Green text-Regular(10_12) text-Accent/Green px-[0.6rem]">
                Выбрать
              </span>
            )}
          </div>
        </div>
        {active && (
          <i
            className={`
					${isOtherShowed ? "rotate-180 text-Accent/Blue" : "text-Content/Middle"}
					 icon-arrow text-Regular(12_14) duration-300 ease-in-out m-auto  flex items-center justify-center`}
          ></i>
        )}
      </div>
    </div>
  );
};

function UsersPanel() {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isModalLogoutOpened, setIsModalLogoutOpened] = useState(false);
  const { authState, login, logout } = useContext(AuthContext);
  const { getData } = useStorage();
  const { username, role } = userStore.currentUser;
  const [candidates, setCandidates] = useState([]);
  const [isOtherShowed, setIsOtherShowed] = useState(false);

  const closeOther = useCallback(() => {
    setIsOtherShowed(false);
  }, []);

  useEffect(() => {
    const users = getData("users");
    const current = users.find((x) => x.active);
    userStore.setCurrentUser(current);
    const readyUsers = users.filter((x) => !x.active);
    setCandidates(readyUsers);
  }, [authState.accessToken, getData]);

  const onLoginHandler = (props) => {
    login(props);
  };

  const onLogoutHandler = () => {
    setIsModalLogoutOpened(false);
    logout();
  };

  return (
    <div
      className="flex flex-col items-start"
      onClick={(ev) => ev.stopPropagation()}
    >
      <div
        onClick={setIsModalLogoutOpened}
        className=" hover:cursor-pointer group flex  text-Content/Middle mb-[0.4rem]"
      >
        <i className="icon-signout text-Regular(12_14) group-hover:text-Accent/Blue"></i>
        <span className=" text-Regular(12_14) block group-hover:text-Accent/Blue">
          Выйти
        </span>
      </div>

      <OutsideAlerter onEvent={closeOther}>
        <div
          onClick={() => setIsOtherShowed(!isOtherShowed)}
          className="w-[32.8rem] relative hover:cursor-pointer"
        >
          <div className="border border-Content/Border hover:border-Accent/Blue ease-in-out duration-300">
            <User
              isOtherShowed={isOtherShowed}
              username={username}
              name={role?.length && role[0].name}
              active={true}
            ></User>
          </div>
          <div
            className={`${
              isOtherShowed
                ? "opacity-100 translate-y-0 duration-300 ease-in-out visible  z-[120]"
                : "opacity-0 translate-y-[10%] invisible  z-20"
            }  absolute  top-[100%] left-0 right-0 bg-white border border-Content/Border border-t-0`}
          >
            <div className="max-h-[30rem] overflow-auto">
              {candidates.map(({ accessToken, username, role, id }) => (
                <div
                  key={accessToken}
                  onClick={() =>
                    onLoginHandler({
                      accessToken,
                      id,
                      username,
                      role,
                    })
                  }
                >
                  <User
                    username={username}
                    name={role?.length && role[0].name}
                  ></User>
                </div>
              ))}
            </div>
            <div className="p-[1.2rem] hover:cursor-pointer hover:bg-Dominant/Dop ease-out duration-300">
              <span className="w-[2.4rem] h-[2.4rem]"></span>
              <span
                onClick={() => setIsModalOpened(true)}
                className="text-Regular(14_16) text-Accent/Blue"
              >
                Добавить нового пользователя
              </span>
            </div>
          </div>
          <Modal
            isOpen={!!isModalOpened}
            closeModal={() => setIsModalOpened(false)}
          >
            <Authorization
              afterLogin={() => setIsModalOpened(false)}
            ></Authorization>
          </Modal>
        </div>
      </OutsideAlerter>
      <Modal
        isOpen={!!isModalLogoutOpened}
        closeModal={() => setIsModalLogoutOpened(false)}
      >
        <ConfirmModal
          title="Вы уверены, что хотите выйти из профиля?"
          onResolve={onLogoutHandler}
          onReject={() => setIsModalLogoutOpened(false)}
        ></ConfirmModal>
      </Modal>
    </div>
  );
}

export default observer(UsersPanel);
