import {useCallback, useRef} from 'react';


export function useDebouncedFunction(func, delay = 300) {
	const ref = useRef(null);

	return {
		func: useCallback((...args) => {
			clearTimeout(ref.current);
			ref.current = setTimeout(() => func(...args), delay);
		}, [func]),
		timeoutId: ref.current
	}
}