import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";

import Auth from '../routes/auth';
import Layout from '../components/layout/layout';
import Main from '../routes/main';

function Desktop({
	authState
}) {



	const router = createBrowserRouter([
		{
			path: "/",
			element: <Main></Main>,
		},
	]);

	return (
		<>
			{

				authState.authenticated ?
					<Layout>
						<RouterProvider router={router} />
					</Layout>
					:

					<Auth></Auth>
			}
		</>
	);
}

export default Desktop;