import Container from "../container/container";
import UsersPanel from "../usersPanel/usersPanel";
import Button from "../ui/button/button";
import useOrder from "../../hooks/order.hook";
import usePosition from '../../hooks/position.hook';
import Notification from "../notification/notification";
import { useEffect } from "react";


function Header() {


	const { drop } = usePosition();

	useEffect(() => {
		window.cleanBase = drop;
	}, [])


	return (
		<>
			<Notification></Notification>
			<Container>
				<div className="pt-[36px] pb-[18px] flex justify-between relative z-50 ">
					<span className="block">
						<img src="/Logo.png" alt="" />
					</span>
					{/* <Button></Button> */}
					{/* <Button
						onPress={drop}
						name="дроп"
					></Button> */}
					<UsersPanel></UsersPanel>
				</div>
			</Container>
		</>


	);
}

export default Header;