import { useEffect, useCallback } from "react";
import useUsers from "./users.hook.js";
import usersStore from "../store/users";
import dashbordStore from "../store/dashbord";
import useSettings from "./settings.hook.js";

function useInit() {
  const { currentUser } = usersStore;
  const { getUsers, getMe } = useUsers();
  const { setInitialSettings } = useSettings();

  const getAllUsers = useCallback(async () => {
    const data = await getUsers();
    usersStore.setUsers(data);
  }, [getUsers]);

  const getCurrentUser = useCallback(async () => {
    const { settings } = await getMe();
    setInitialSettings(settings);
  });

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (currentUser.id) {
      getCurrentUser();
    }
  }, [currentUser.id]);
}

export default useInit;
