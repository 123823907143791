import { useEffect, useRef, useState } from "react";
import DropdownHOC from "../../dropdownHOC/dropdownHOC";
import stageStore from "../../../store/stage";
import DefaultModal from "../defaultModal/defaultModal";
import Button from "../../ui/button/button";
import usePosition from "../../../hooks/position.hook";
import usersStore from "../../../store/users";
import ButtonBorder from "../../ui/buttonBorder/buttonBorder";
import Checkbox from "../../ui/checkbox/checkbox";
import useOrder from "../../../hooks/order.hook";

function OrderCommentModal({ orderCommentModal, getData, setOrderCommentModal }) {


  const { orderId, comment, id, callback } = orderCommentModal;
  const [value, setValue] = useState(comment);
  const { setComment } = useOrder();

  const textareaRef = useRef(null);
  const onSetComment = async () => {
    await setComment({
		orderId,
      comment: textareaRef.current.value,
    });
	 setOrderCommentModal({
		isOpen: false,
		orderId: null,
		id:null,
		comment: ''
	  })
	  callback ? callback() : getData(true)
  };

  return (
    <div className="w-[90vw] max-w-[76rem] min-h-[10rem]">
      <DefaultModal
        title={`${
          comment ? "Изменить" : "Оставить"
        } комментарий на весь заказ ${orderId}`}
      >
        <div className="bg-Content/Border mb-[2rem] p-[1rem] text-Regular(16_18)">
          Внимание! Ваш комментарий будет выводиться для всех позиций заказа
        </div>

        <textarea
          placeholder="Введите комментарий"
          ref={textareaRef}
          value={value}
			 onInput={({target:{value}}) =>setValue(value)}
          className="p-[1.2rem] text-Regular(16_20) border w-[100%] border-Content/Border rounded-[4px] min-h-[14rem]"
        />
        <div className="flex justify-end mt-[2rem]">
          <Button
            onPress={onSetComment}
            name={`${comment ? "Изменить" : "Отправить"}`}
          ></Button>
        </div>
      </DefaultModal>
    </div>
  );
}

export default OrderCommentModal;
