import { useEffect, useState, useRef, useCallback } from "react";
import { date } from "../../../utils/date";
import useOrder from "../../../hooks/order.hook";
import Loader from "../../loader/loader";
import ReadyTimePicker from "../../readyTimePicker/readyTimePicker";
import stageStore from "../../../store/stage";
import dashbordStore from "../../../store/dashbord";
import usePosition from "../../../hooks/position.hook";
import Checkbox from "../../ui/checkbox/checkbox";
import ButtonBorderRound from "../../ui/buttonBorderRound/buttonBorderRound";
import ButtonGrey from "../../ui/buttonGrey/buttonGrey";
import Modal from "../../modal/modal";
import ConfirmModal from "../confirmModal/confirmModal";
import OrderDetail from "./orderDetail";
import OrderStages from "./orderStages";
import { observer } from "mobx-react-lite";
import { emmiter } from "../../../helpers/Emmiter";
import { useDebouncedFunction } from "../../../hooks/debounce.hook";
import ButtonIcon from "../../ui/buttonIcon/buttonIcon";
import usersStore from "../../../store/users";
import "./style.css";

function OrderModal({ orderId, onPositionDetail, onSetPositionModalPrint }) {
  const intervalId = useRef(null);
  const { getOrder } = useOrder();
  const { setPositionsToUrgent, setPositionsToCanceled, setPositionsToClosed } =
    usePosition();
  const { selectedPositionsStages } = dashbordStore;
  const selectedLength = selectedPositionsStages.length;

  const { stages } = stageStore;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isUrgent, setIsUrgent] = useState(false);
  const [isModalCancelOpened, setIsModalCancelOpened] = useState(false);
  const [isModalCloseOpened, setIsModalCloseOpened] = useState(false);
  const [tab, setTab] = useState(1);

  const getData = useCallback(async () => {
    if (!orderId) return;

    setLoading(true);

    const positionOptions =
      tab === 1
        ? {

            drawings: true,
            c_position_stages: {
              filters: {
                isCurrentStage: true,
              },
              populate: {
                stage: true,
                status: false,
              },
            },
          }
        : {
            drawings: {
              populate: {
                user: true,
                status: true,
              },
            },
            c_position_stages: {
              populate: {
                status: true,
                user: true,
                comments: true,
                stage: true,
              },
            },
          };

    const { data } = await getOrder({
      id: orderId,
      positionOptions,
    });

    data.positions.forEach((x) => {
      const positonStages = stages.reduce((acc, curr, index) => {
        let positionStageItem;
        const currentStatusStep = curr.step;
        let isStageItemAdded = false;
        for (let i = 0; i < x.c_position_stages.length; i++) {
          const isLastIteration = i === x.c_position_stages.length - 1;
          if (
            currentStatusStep === x.c_position_stages[i].stage.step &&
            !isStageItemAdded
          ) {
            positionStageItem = {
              ...curr,
              item: x.c_position_stages[i],
            };
            acc.push(positionStageItem);
            isStageItemAdded = true;
          }
          if (isLastIteration && !isStageItemAdded) {
            positionStageItem = {
              ...curr,
              item: undefined,
            };
            acc.push(positionStageItem);
          }
        }
        return acc;
      }, []);
      x.stages = positonStages;
    });
    setData(data);
    setIsUrgent(data.isUrgent);
    setLoading(false);
  }, [getOrder, orderId, stages, tab]);

  const { func: debouncedGetData, timeoutId: getDataTimeoutId } =
    useDebouncedFunction(() => getData(), 3000);

  const onUrgent = async () => {
    setIsUrgent(!isUrgent);
  };

  const onCloseOrder = async () => {
    const ids = data.positions.map((x) => x.id);
    setIsModalCloseOpened(false);
    await setPositionsToClosed(ids);
    getData();
  };

  const onCancelOrder = async () => {
    const ids = data.positions.map((x) => x.id);
    setIsModalCancelOpened(false);
    await setPositionsToCanceled(ids);
    getData();
  };

  const selectAllPositions = () => {
    if (selectedLength === data.positions.length) {
      return dashbordStore.addPositions([]);
    }
    const ids = data.positions.map((x) => ({
      position_id: x.id,
      id: x.c_position_stages[0]?.id,
    }));
    dashbordStore.addPositions(ids);
  };

  const onSelectPosition = (id, c_position_ID) => {
    dashbordStore.addPosition({
      id: c_position_ID,
      position_id: id,
    });
  };

  useEffect(() => {
    if (data?.isUrgent === isUrgent || !intervalId.current) return;
    if (data?.positions) {
      (async () => {
        const positionsIds = data.positions.map((x) => x.id);
        await setPositionsToUrgent({
          ids: positionsIds,
          isUrgent,
        });
      })();
    }
  }, [isUrgent]);

  useEffect(() => {
    dashbordStore.clearSelected();
    getData();
    intervalId.current = setInterval(getData, 20000);
    return () => {
      clearInterval(intervalId.current);
    };
  }, [getData]);

  const finishAction = (isGetData = false) => {
    dashbordStore.clearSelected();
    isGetData && getData(true);
  };

  const setOrderComment =  ({
	orderId, 
	comment = ''
  }) => {
	emmiter.emmit('setOrderCommentModal', {
		isOpen: true,
		orderId,
		comment,
		callback : getData
	  })
  }

  const setPositionsToUrgentHandler = async () => {
    console.log(dashbordStore.selectedPositionsStages);
    await setPositionsToUrgent({
      ids: dashbordStore.selectedPositionsStages.map((x) => x.position_id),
      isUrgent: true,
    });
    finishAction(true);
  };
  const setPositionsToCanceledHandler = async () => {
    await setPositionsToCanceled(
      dashbordStore.selectedPositionsStages.map((x) => x.position_id)
    );
    finishAction(true);
  };

  let orderComment;



  orderComment = data?.localComment?.comment || data?.managerComment

  return (
    <div className="w-[90vw] max-w-[180rem] min-h-[50rem]">
      {loading && !data && <Loader></Loader>}
      {data && (
        <>
          <div className="py-[3.6rem] pt-[5.2rem] px-[8rem] bg-Accent/Light_Yellow">
            <div className="grid grid-cols-2 gap-[2.6rem] mb-[2.4rem]">
              <div className="flex gap-[3.6rem] items-center">
                <div className="flex gap-[0.5rem]">
                  <span className="block text-Regular(16_20)">Заказ:</span>
                  <span className="block text-Regular(16_20) font-bold">
                    №{data.orderId || "-"}
                  </span>
                </div>
                <div className="flex gap-[0.5rem]">
                  <span className="block text-Regular(16_20)">
                    Дата заказа:
                  </span>
                  <span className="block text-Regular(16_20) font-bold">
                    {data.date || "-"}
                  </span>
                </div>
                <div className="flex gap-[0.5rem]">
                  <span className="block text-Regular(16_20)">Менеджер:</span>
                  <span className="block text-Regular(16_20) font-bold">
                    {data.manager || "-"}
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex gap-[0.5rem]">
                  <span className="block text-Regular(16_20)">
                    Статус заказа:
                  </span>
                  <span className="block text-Regular(16_20) font-bold">
                    {data.order_status?.name}
                  </span>
                </div>
                <Checkbox
                  onChange={onUrgent}
                  label="Срочный заказ"
                  className="text-Accent/Red font-medium"
                  active={isUrgent}
                ></Checkbox>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-x-[2.6rem] gap-y-[1.2rem]">
              <div className="flex flex-col gap-[1.2rem]">
                <div className="bg-white px-[2.6rem] py-[1.8rem] grid grid-cols-[auto_1fr] gap-x-[1rem] gap-y-[1.2rem]">
                  <div className="text-Regular(14_16)">Заказчик:</div>
                  <div className="text-Regular(14_16) font-bold">
                    {data.company || "-"}
                  </div>
                  <div className="text-Regular(14_16)">ФИО клиента:</div>
                  <div className="text-Regular(14_16) font-bold">
                    {(data.userinfo?.name || '-')  + " " + (data.userinfo?.surname || '')}
                  </div>
                  <div className="text-Regular(14_16)">EMAIL клиента:</div>
                  <div className="text-Regular(14_16) font-bold">
                    {data.userinfo?.email || "-"}
                  </div>
                  <div className="text-Regular(14_16)">Телефон клиента:</div>
                  <div className="text-Regular(14_16) font-bold">
                    {data.userinfo?.phone || "-"}
                  </div>
                </div>
                <div className="bg-white px-[2.6rem] py-[1.8rem] grid grid-cols-[auto_1fr] gap-x-[1rem] gap-y-[1.2rem]">
                  <div className="text-Regular(14_16)">Доставка:</div>
                  <div className="text-Regular(14_16) font-bold">
                    <span>{data.deliveryinfo?.name}</span>
                    {data.deliveryinfo?.descr && (
                      <span> ({data.deliveryinfo?.descr})</span>
                    )}
                  </div>
                </div>
              </div>
				  
              <div className="flex flex-col gap-[1.2rem]">
                <div className="bg-white px-[2.6rem] py-[1.8rem] text-Regular(16_18) grid grid-cols-2">
                  <div className="flex gap-[5px]">
                    <span>Время выполнения:</span>
                    <span className="font-bold">-</span>
                  </div>
                  <div className="flex gap-[5px]">
                    <span>Дата готовности:</span>
                    <span className="font-bold">{date.transform(data.readyDate) || "-"}</span>
						  <ReadyTimePicker
						  
                      small={true}
                      orderId={data.id}
                      callback={() => getData(true)}
                    ></ReadyTimePicker>
                  </div>
                </div>
                <div className="bg-white px-[2.6rem] py-[1.8rem] ">
                  <div className="text-Regular(16_20) mb-[0.8rem] font-bold">
                    Комментарий клиента к заказу:
                  </div>
                  <div className="text-Regular(16_20)">
                    {data.comment || "-"}
                  </div>
                </div>
					 
                {data.layout?.length > 0 && (
                  <div className="bg-white px-[2.6rem] py-[1.8rem] grid  gap-y-[3rem]">
                    {data.layout.map(({ link, comment, name, size }) => {
                      return (
                        <div className="flex flex-col gap-[1rem]">
                          {link && (
                            <div>
                              <div className="text-Regular(16_18) font-medium mb-[1rem]">
                                Макет:
                              </div>
                              <a href={link} className="flex">
                                <i className="ml-[-1rem] icon-file-download text-[4rem] text-Accent/Blue hover:cursor-pointer leading-[0]"></i>
                                <div className="flex flex-col gap-[0.5rem]">
                                  <span className="text-Regular(12_14) text-Content/Middle line-clamp-1">
                                    {name}
                                  </span>
                                  <span className="text-Regular(12_14) text-Content/Middle">
                                    {size / 1000} kb
                                  </span>
                                </div>
                              </a>
                            </div>
                          )}
                          {comment && (
                            <div>
                              <div className="text-Regular(16_18) font-medium mb-[1rem]">
                                Комментарий:
                              </div>
                              <span className="text-Regular(14_16)">
                                {comment}
                              </span>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="py-[3.6rem] px-[8rem] ">
			 <div className="flex items-center mb-[3rem] w-[100%] gap-[1rem]">
              {!!orderComment && (
                <div className="  gap-[1rem] bg-Accent/Light_Yellow  text-Regular(14_16) px-[1rem] py-[2px] leading-1">
                  <span className="font-medium">
                    Комментарий администратора к заказу:
                  </span>
                  <span> {orderComment}</span>
                </div>
              )}
              {usersStore.isGodMod() && (
                <div
                  onClick={() =>
                    setOrderComment({
                      orderId:data.id,
                      comment: orderComment,
                    })
                  }
                  className=" ease-in-out flex-none duration-300 bg-Content/Border w-[5rem] ml-[auto] flex justify-center p-[2px] hover:cursor-pointer hover:opacity-60"
                >
                  <i
                    className={`icon-comment text-Regular(24_30) hover:cursor-pointer  text-Accent/Blue`}
                  ></i>
                </div>
              )}
            </div>
            <div className="flex text-Regular(16_18)">
              <div
                onClick={() => setTab(1)}
                className={`${
                  tab === 1
                    ? "before:absolute before:left-0 before:right-0 before:top-0 before:h-[2px] before:bg-Accent/Yellow  border-Content/Border pointer-events-none"
                    : "border-[transparent] text-Content/Middle"
                } border  font-semibold relative ease-in-out duration-300 hover:cursor-pointer hover:bg-Dominant/Dop hover:text-Content/Middle py-[1.2rem] w-[20rem] text-center border-b-0 `}
              >
                Детали заказа
              </div>
              <div
                onClick={() => setTab(2)}
                className={`${
                  tab === 2
                    ? " before:absolute before:left-0 before:right-0 before:top-0 before:h-[2px] before:bg-Accent/Yellow  border-Content/Border pointer-events-none"
                    : "text-Content/Middle border-[transparent]"
                } relative  font-semibold border ml-[-1px] ease-in-out duration-300 hover:cursor-pointer hover:bg-Dominant/Dop hover:text-Content/Middle py-[1.2rem] w-[20rem] text-center  border-b-0 `}
              >
                Движение по этапам
              </div>
            </div>

            <div className="overflow-auto  pb-[3rem]">
              {tab == 1 ? (
                <OrderDetail
                  selectedPositionsStages={selectedPositionsStages}
                  onSelectPosition={onSelectPosition}
                  selectAllPositions={selectAllPositions}
                  onPositionDetail={onPositionDetail}
                  data={data}
                  getData={getData}
                ></OrderDetail>
              ) : (
                <OrderStages
                  selectedPositionsStages={selectedPositionsStages}
                  onSelectPosition={onSelectPosition}
                  selectAllPositions={selectAllPositions}
                  onPositionDetail={onPositionDetail}
                  data={data}
                  getData={getData}
                  debouncedGetData={debouncedGetData}
                ></OrderStages>
              )}
            </div>
            <div className="flex justify-between mt-[1.8rem]">
              <div
                className={`flex gap-[2.6rem] duration-300 ${
                  selectedLength
                    ? "opacity-[100%] pointer-events-auto"
                    : "opacity-[20%] pointer-events-none"
                }`}
              >
                <ButtonIcon
                  onPress={setPositionsToUrgentHandler}
                  Icon={() => (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_902_27652)">
                        <path
                          d="M10 11.8335C9.77342 11.8335 9.55188 11.9007 9.36345 12.0266C9.17502 12.1525 9.02816 12.3315 8.94143 12.5408C8.85471 12.7502 8.83201 12.9806 8.87623 13.2029C8.92044 13.4251 9.02957 13.6293 9.18982 13.7896C9.35006 13.9498 9.55423 14.0589 9.7765 14.1031C9.99877 14.1474 10.2292 14.1247 10.4385 14.0379C10.6479 13.9512 10.8269 13.8043 10.9528 13.6159C11.0787 13.4275 11.1459 13.2059 11.1459 12.9793C11.1459 12.6754 11.0252 12.384 10.8103 12.1691C10.5954 11.9542 10.3039 11.8335 10 11.8335ZM10 10.4585C10.2432 10.4585 10.4763 10.3619 10.6482 10.19C10.8201 10.0181 10.9167 9.78494 10.9167 9.54183V6.79183C10.9167 6.54871 10.8201 6.31555 10.6482 6.14365C10.4763 5.97174 10.2432 5.87516 10 5.87516C9.75693 5.87516 9.52377 5.97174 9.35186 6.14365C9.17995 6.31555 9.08338 6.54871 9.08338 6.79183V9.54183C9.08338 9.78494 9.17995 10.0181 9.35186 10.19C9.52377 10.3619 9.75693 10.4585 10 10.4585ZM10 0.833496C8.18705 0.833496 6.41477 1.37111 4.90732 2.37836C3.39987 3.3856 2.22495 4.81724 1.53115 6.49223C0.837346 8.16722 0.655815 10.0103 1.00951 11.7885C1.36321 13.5666 2.23625 15.2 3.51823 16.482C4.80021 17.764 6.43356 18.637 8.21172 18.9907C9.98987 19.3444 11.833 19.1629 13.508 18.4691C15.183 17.7752 16.6146 16.6003 17.6218 15.0929C18.6291 13.5854 19.1667 11.8132 19.1667 10.0002C19.164 7.56984 18.1974 5.23983 16.4789 3.52134C14.7604 1.80284 12.4304 0.836199 10 0.833496ZM10 17.3335C8.54965 17.3335 7.13182 16.9034 5.92586 16.0976C4.7199 15.2918 3.77997 14.1465 3.22493 12.8065C2.66989 11.4665 2.52466 9.99202 2.80762 8.5695C3.09058 7.14697 3.78901 5.8403 4.81459 4.81471C5.84018 3.78913 7.14685 3.0907 8.56938 2.80774C9.99191 2.52478 11.4664 2.67 12.8064 3.22505C14.1464 3.78009 15.2917 4.72002 16.0975 5.92598C16.9033 7.13194 17.3334 8.54976 17.3334 10.0002C17.3311 11.9444 16.5578 13.8084 15.183 15.1831C13.8082 16.5579 11.9443 17.3313 10 17.3335Z"
                          fill="#E30613"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_902_27652">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  className="border-Accent/Red"
                  name="Сделать срочными"
                ></ButtonIcon>
                <ButtonIcon
                  onPress={setPositionsToCanceledHandler}
                  Icon={() => (
                    <i
                      className={`icon-stage-12 text-gray-300 text-Regular(18_24)`}
                    ></i>
                  )}
                  className="border-gray-300"
                  name="Отменить позиции"
                ></ButtonIcon>
              </div>
              <div className="flex gap-[2.6rem]">
                <ButtonGrey
                  onPress={() => setIsModalCancelOpened(true)}
                  name="Отменить весь заказ"
                ></ButtonGrey>
                <ButtonBorderRound
                  onPress={() => setIsModalCloseOpened(true)}
                  name="Закрыть весь заказ"
                ></ButtonBorderRound>
              </div>
            </div>
          </div>
        </>
      )}
      <Modal
        isOpen={isModalCancelOpened}
        closeModal={() => setIsModalCancelOpened(false)}
      >
        <ConfirmModal
          title="Вы уверены, что хотите отменить весь заказ?"
          onResolve={onCancelOrder}
          onReject={() => setIsModalCancelOpened(false)}
        ></ConfirmModal>
      </Modal>
      <Modal
        isOpen={isModalCloseOpened}
        closeModal={() => setIsModalCloseOpened(false)}
      >
        <ConfirmModal
          title="Вы уверены, что хотите закрыть весь заказ?"
          onResolve={onCloseOrder}
          onReject={() => setIsModalCloseOpened(false)}
        ></ConfirmModal>
      </Modal>
    </div>
  );
}

export default observer(OrderModal);
