import { useCallback, useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import { observer } from "mobx-react-lite";

import useDashbord from "../../hooks/dashbord.hook";
import stageStore from "../../store/stage";
import Position from "../position/position-mobile";
import Container from "../container/container";
import useUsers from "../../hooks/users.hook";
import OrderModal from "../modals/orderModal/orderModal-mobile";
import PositionModal from "../modals/positionModal/positionModal-mobile";
import Modal from "../modal/modal-mobile";
import Switcher from "../ui/swticher/switcher";
import Pagination from "../pagination/pagination-mobile";
import ActionDropdown from "../actionDropdown/actionDropdown";
import SortDropdown from "../sortDropdown/sortDropdown";
import useInit from "../../hooks/init.hook";
import dashbordStore from "../../store/dashbord";
import usersStore from "../../store/users";
import Search from "./search/search-mobile";
import CommentModal from "../modals/commentModal/commentModal-mobile";
import MoveToStageModal from "../modals/moveToStageModal/moveToStageModal-mobile";
import StickerModal from "../modals/stickerModal/stickerModal-mobile";
import Loader from "../loader/loader";
import OrderCommentModal from "../modals/orderCommentModal/orderCommentModal-mobile";
import "./style.css";
import ButtonResolve from "../ui/buttonResolve/buttonResolveMobile";
import ButtonReject from "../ui/buttonReject/buttonRejectMobile";
import ButtonBorderRound from "../ui/buttonBorderRound/buttonBorderRoundMobile";
import usePosition from "../../hooks/position.hook";
import ConfirmModal from "../modals/confirmModal/confirmModal-mobile";
import useSettings from "../../hooks/settings.hook";
import { emmiter } from "../../helpers/Emmiter";
import BottomSheet from "../modal/bottomSheet";
import Settings from "./settings/settings-mobile";
import { useDebouncedFunction } from "../../hooks/debounce.hook";
import { StageStatuses } from "../stageStatuses/stageStatuses";
import { date } from "../../utils/date";
import StampProgressModal from "../modals/stampProgressModal/stampProgressModalMobile";
import ReadyTimePicker from "../readyTimePicker/readyTimePicker";

function Dashbord() {
  useInit();
  const [filter, setFilter] = useState(null);
  const intervalId = useRef(null);
  const prevSortState = useRef(null);
  const { getDashbord } = useDashbord();
  const { getUsers } = useUsers();
  const { updateGroupView } = useSettings();
  const {
    settings: { groupView, table, sort },
    pageSize,
    selectedPositionsStages,
    selectedPositionsOrderId,
  } = dashbordStore;
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState([]);
  const [isAllMod, setIsAllMod] = useState(false);
  const [completeOrderModal, setCompleteOrderModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [positionModal, setPositionModal] = useState(false);
  const [satusAlertModal, setAlertStatusModal] = useState(false);
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const { setPositionsToDelivered, setStatus } = usePosition();
  const [commentModal, setCommentModal] = useState({
    isOpen: false,
    positionId: null,
    stageId: null,
    isFullMod: true,
    callback: null,
    positionsIDs: [],
  });
  const [moveToStageModal, setMoveToStageModal] = useState({
    isOpen: false,
    positionId: null,
    callback: null,
  });
  const [orderCommentModal, setOrderCommentModal] = useState({
    isOpen: false,
    id: null,
    comment: "",
    orderId: null,
  });
  const [stickerModal, setStickerModal] = useState({
    isOpen: false,
  });
  const { id, role, statuses, step } = stageStore.currentStage || {};
  const { stages, currentStage } = stageStore || {};
  const [loading, setLoading] = useState(false);
  const onSetFilter = useCallback(
    (filter) => {
      setFilter(filter);
    },
    [setFilter]
  );

  const getAllUsers = useCallback(async () => {
    const data = await getUsers();
    usersStore.setUsers(data);
  }, [getUsers]);

  const onPageChange = (page) => {
    setPage(page);
  };

  const getData = useCallback(
    async (silent = false) => {
      if (!table) return;

      if (id || id === null) {
        !silent && setLoading(true);

        const {
          data,
          meta: { pagination },
        } = await getDashbord({
          stage: id,
          page: page,
          pageSize,
          filter,
          sort: sort,
        });

        if (!id) {
          setIsAllMod(true);
        } else {
          setIsAllMod(false);
        }

        if (!id) {
          const stackedPositions = data.reduce((acc, curr, index) => {
            const currentPositionID = curr.position.id;
            const formattedCurrent = {
              ...curr,
              stageInfo: [{ stage: curr.stage, status: curr.status }],
            };
            if (index === 0) {
              acc.push(formattedCurrent);
              return acc;
            } else {
              acc.forEach((el, i) => {
                const positionId = el.position.id;
                if (currentPositionID === positionId) {
                  el.stageInfo.push({ stage: curr.stage, status: curr.status });
                } else if (
                  currentPositionID !== positionId &&
                  i === acc.length - 1
                ) {
                  return acc.push(formattedCurrent);
                }
              });
            }
            return acc;
          }, []);
          setItems(stackedPositions);
          if (groupView) {
            setItems(groupItems(stackedPositions));
          }
        } else {
          if (groupView) {
            setItems(groupItems(data));
          } else {
            if (sort.custom) {
              prevSortState.current = data.map((x) => x.id);
            }
            setItems(data);
          }
        }

        setMeta(pagination);
        !silent && setLoading(false);
      }
    },
    [getDashbord, id, page, filter, groupView, table, sort, pageSize]
  );

  const { func: debouncedGetData, timeoutId: getDataTimeoutId } =
    useDebouncedFunction(() => getData(true), 3000);

  useEffect(() => {
    clearTimeout(getDataTimeoutId);
  }, [currentStage]);

  const onOrderDetail = (id) => {
    setDetailModal(id);
    setPositionModal(false);
  };

  const onPositionDetail = (id) => {
    setPositionModal(id);
  };

  const groupItems = (data) => {
    const group = data.reduce((acc, curr) => {
      const { orderId, localComment, id, managerComment, readyDate } =
        curr.position.order;
      const deliveryinfo = curr.position.order.deliveryinfo?.name;
      if (acc.get(id)) {
        acc.get(id).items.push(curr);
      } else {
        acc.set(id, {
          items: [curr],
          deliveryinfo,
          readyDate: date.transform(readyDate),
          localComment,
          orderId,
          managerComment,
        });
      }
      return acc;
    }, new Map());

    const result = [];
    for (const item of group) {
      result.push(item);
    }
    return result;
  };

  const setComment = ({ id, stageId }) => {
    setCommentModal((prev) => ({
      ...prev,
      isOpen: true,
      stageId,
      positionId: id,

      isFullMod: true,
      positionsIDs: [],
    }));
  };

  const setOrderComment = ({ id, orderId, comment = "" }) => {
    setOrderCommentModal({
      isOpen: true,
      id,
      orderId,
      comment,
    });
  };

  const setSticker = (props) => {
    setStickerModal({
      isOpen: true,
      ...props,
    });
  };

  const closeCommentModalAndReftesh = async () => {
    commentModal.callback && (await commentModal.callback());

    setCommentModal((prev) => ({
      ...prev,
      isOpen: false,
      isFullMod: true,
      stageId: null,
      positionId: null,
      callback: null,
      positionsIDs: [],
    }));

    await getData(true);
  };

  const onCompleteOrder = async (item) => {
    const ids = item.map((x) => x.position.id);
    setCompleteOrderModal(false);
    await setPositionsToDelivered(ids);
    getData(true);
  };

  const onChangeStatusOrder = async (item, type) => {
    const { id } = statuses.find((x) => x[type]);
    for (const position of item) {
      await setStatus(position.id, id);
    }
    setCompleteOrderModal(false);
    getData(true);
  };

  useEffect(() => {
    emmiter.on("setCommentModal", (ev) => {
      setCommentModal(ev);
    });
    return () => {
      emmiter.off("setCommentModal");
    };
  }, []);

  useEffect(() => {
    emmiter.on("setMoveToStageModal", (ev) => {
      setMoveToStageModal(ev);
    });
    return () => {
      emmiter.off("setMoveToStageModal");
    };
  }, []);

  const render = (
    {
      isUrgent,
      stageInfo,
      blocked,
      comments,
      stage,
      position,
      user,
      id,
      createdAt,
      dashbordDate,
      status: currentStatus,
      isCurrentStage,
      stageChangeTimeStamps,
    },
    index
  ) => {
    return (
      <Position
        {...position}
        key={id}
        index={index}
        isUrgent={isUrgent}
        positionStageId={id}
        timestamps={stageChangeTimeStamps}
        user={user}
        statuses={statuses}
        status={currentStatus}
        comments={comments}
        settings={table}
        isAllMod={isAllMod}
        dashbordDate={dashbordDate}
        role={role}
        stage={stage}
        isCurrentStage={isCurrentStage}
        blocked={blocked}
        createdAt={createdAt}
        onOrderDetail={onOrderDetail}
        onPositionDetail={onPositionDetail}
        setComment={setComment}
        setSticker={setSticker}
        getData={getData}
        debouncedGetData={debouncedGetData}
        groupView={groupView}
        selectedPositionsOrderId={selectedPositionsOrderId}
        stageInfo={stageInfo}
      ></Position>
    );
  };

  useEffect(() => {
    emmiter.on("setUserAlert", () => setAlertStatusModal(true));
    return () => {
      emmiter.off("setUserAlert");
    };
  }, []);

  useEffect(() => {
    getData();
    intervalId.current = setInterval(() => getData(true), 20000);
    return () => {
      clearInterval(intervalId.current);
    };
  }, [getData]);

  useEffect(() => {
    setPage(1);
  }, [id]);

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  return (
    <>
      <div className="bg-Dominant/Dop  flex-1 flex flex-col gap-[0.8rem] min-h-[100px] relative z-[100] w-[100%]">
        <div className="px-[18px] flex-1">
          <div className="m-auto w-[100%] mb-[6px]">
            <div className="p-[1.2rem] bg-white flex gap-[12px] justify-between">
              <Settings></Settings>
              <Search
                setPage={setPage}
                isAllMod={isAllMod}
                setFilter={onSetFilter}
                statuses={statuses}
                stages={stages}
              ></Search>
              <SortDropdown></SortDropdown>
            </div>
          </div>
          <div className="flex-1   w-[100%] m-auto relative overflow-hidden">
            {loading ? (
              <Loader></Loader>
            ) : table && !!items.length ? (
              <div className="js-scrollable-dashbord overflow-auto h-[100%]">
                {Array.isArray(items[0]) ? (
                  items?.map(
                    ([
                      id,
                      {
                        items: item,
                        localComment,
                        orderId,
                        readyDate,
                        managerComment,
                      },
                    ]) => {
                      let orderComment;
                      orderComment = localComment?.comment || managerComment;
                      return (
                        <div key={id} className="">
                          <div className=" items-center justify-between my-[1.1rem] w-[100%]">
                            <div className="flex items-center gap-[1rem] justify-end mb-[10px]">
                              {readyDate && (
                                <div
                                  data-tooltip-id={"comment-info"}
                                  data-tooltip-content="Время готовности заказа"
                                  data-tooltip-place="top"
                                  className="bg-Accent/Blue_Light  truncate text-Regular(14_16)  px-[1rem] py-[2px] leading-1"
                                >
                                  {readyDate}
                                </div>
                              )}
                              <ReadyTimePicker
                                tooltip="Время готовности заказа"
                                orderId={id}
                                callback={() => getData(true)}
                              ></ReadyTimePicker>
                            </div>
                            <div className="flex items-center gap-[1rem] mb-[1.1rem] max-w-[100%] w-[100%]">
                              <span className="text-Content/Dark block font-semibold text-Regular(16_18) ">
                                {id}{" "}
                              </span>
                              {orderComment && (
                                <div
                                  data-tooltip-id={"comment-info"}
                                  data-tooltip-content={orderComment}
                                  data-tooltip-place="top"
                                  className="bg-Accent/Light_Yellow truncate text-Regular(14_16) px-[1rem] py-[2px] leading-1"
                                >
                                  {orderComment}
                                </div>
                              )}

                              {usersStore.isGodMod() && (
                                <div
                                  onClick={() =>
                                    setOrderComment({
                                      orderId:id,
                                      id,
                                      comment: orderComment,
                                    })
                                  }
                                  className="ml-[auto] ease-in-out flex-none duration-300 bg-[#fff]  ml-[auto] flex justify-center p-[2px] hover:cursor-pointer hover:opacity-60"
                                >
                                  <i
                                    className={`icon-comment text-Regular(24_30) hover:cursor-pointer  text-Accent/Blue`}
                                  ></i>
                                </div>
                              )}
                            </div>

                            {currentStage.step === 9 && (
                              <div className="flex items-center gap-[15px]">
                                <ButtonBorderRound
                                  Icon={() => (
                                    <i
                                      className="icon-stage-9 
																		text-Content/Middle
																		 text-Regular(18_24)"
                                    ></i>
                                  )}
                                  onPress={() =>
                                    setCompleteOrderModal({
                                      message: `Вы уверены, что хотите проставить статус "В пути" для заказа ${id}?`,
                                      callback: () =>
                                        onChangeStatusOrder(
                                          item,
                                          "deliveryOnTheWay"
                                        ),
                                      id,
                                      item,
                                    })
                                  }
                                  name="В пути"
                                ></ButtonBorderRound>
                                <ButtonReject
                                  onPress={() =>
                                    setCompleteOrderModal({
                                      message: `Вы уверены, что хотите проставить статус "Не доставлен" для заказа ${id}?`,
                                      callback: () =>
                                        onChangeStatusOrder(
                                          item,
                                          "deliveryNotDelivered"
                                        ),
                                      id,
                                      item,
                                    })
                                  }
                                  name="Не доставлен"
                                ></ButtonReject>
                                <ButtonResolve
                                  onPress={() =>
                                    setCompleteOrderModal({
                                      message: `Вы уверены, что хотите подтвердить выполнение заказа ${id}?`,
                                      callback: () => onCompleteOrder(item),
                                      id,
                                      item,
                                    })
                                  }
                                  name="Заказ выполнен"
                                ></ButtonResolve>
                              </div>
                            )}
                          </div>
                          {item.map(render)}
                        </div>
                      );
                    }
                  )
                ) : (
                  <>{items?.map(render)}</>
                )}
              </div>
            ) : (
              <span className="relative block mt-[10%] text-center text-Regular(24_30) text-Content/Light">
                Данных пока нет
              </span>
            )}
          </div>
        </div>

        {!!items.length && !loading && (
          <>
            <Container>
              <div className="bg-[#fff] p-[12px]">
                <Pagination {...meta} onPageChange={onPageChange}></Pagination>
              </div>
            </Container>
            <div className="sticky bottom-0 py-[20px] bg-[#fff] shadow-default z-20 flex flex-col gap-[10px]">
              {!!statuses && (
                <StageStatuses
                  stageStatuses={statuses}
                  step={step}
                  isMobileView={true}
                  isMobileModalOpen={isMobileModalOpen}
                  setIsMobileModalOpen={setIsMobileModalOpen}
                />
              )}
              <Container>
                <div className="grid grid-cols-[1fr_1fr] gap-[24px] whitespace-nowrap">
                  {!!selectedPositionsStages.length ? (
                    <ActionDropdown
                      statuses={statuses}
                      setIsMobileModalOpen={setIsMobileModalOpen}
                      onEvent={() => getData(true)}
                    ></ActionDropdown>
                  ) : (
                    <div></div>
                  )}
                  <Switcher
                    active={groupView}
                    onChange={() => updateGroupView(!groupView)}
                    label="Сгруппировать по заказам"
                  ></Switcher>
                </div>
              </Container>
            </div>
          </>
        )}
      </div>

      <BottomSheet
        closeModal={() => setDetailModal(false)}
        isOpen={!!detailModal}
      >
        <OrderModal
          onPositionDetail={onPositionDetail}
          orderId={detailModal}
        ></OrderModal>
      </BottomSheet>
      <BottomSheet
        closeModal={() => setPositionModal(false)}
        isOpen={!!positionModal}
      >
        <PositionModal
          onOrderDetail={onOrderDetail}
          setCommentModal={setCommentModal}
          positionId={positionModal}
          getDashbord={debouncedGetData}
        ></PositionModal>
      </BottomSheet>

      <Modal
        title="Создать стикер"
        closeModal={() =>
          setStickerModal((prev) => ({ ...prev, isOpen: false }))
        }
        isOpen={stickerModal.isOpen}
      >
        <StickerModal
          stickerModal={stickerModal}
          setStickerModal={setStickerModal}
        ></StickerModal>
      </Modal>
      <Modal
        isOpen={satusAlertModal}
        closeModal={() => setAlertStatusModal(false)}
      >
        <ConfirmModal title="Выберите ответственного, чтобы иметь возможность выставлять статус позиции"></ConfirmModal>
      </Modal>
      <StampProgressModal></StampProgressModal>
      <Modal
        isOpen={completeOrderModal}
        closeModal={() => setCompleteOrderModal(false)}
      >
        <ConfirmModal
          title={completeOrderModal.message}
          onResolve={completeOrderModal.callback}
          onReject={() => setCompleteOrderModal(false)}
        ></ConfirmModal>
      </Modal>
      <Modal
        closeModal={() =>
          setOrderCommentModal({
            isOpen: false,
            orderId: null,
            id: null,
            comment: "",
          })
        }
        isOpen={orderCommentModal.isOpen}
      >
        <OrderCommentModal
          setOrderCommentModal={setOrderCommentModal}
          orderCommentModal={orderCommentModal}
          getData={getData}
        ></OrderCommentModal>
      </Modal>
      <Tooltip
        style={{
          zIndex: 1000,
          fontSize: "14px",
        }}
        openOnClick={true}
        id={"cell-click-alert"}
      ></Tooltip>
      <Tooltip
        style={{
          zIndex: 1000,
          fontSize: "14px",
        }}
        id={"cell-hover-alert"}
      ></Tooltip>
      <Tooltip
        style={{
          zIndex: 1000,
          fontSize: "14px",
          maxWidth: "600px",
        }}
        id={"comment-info"}
      ></Tooltip>
      <Modal
        title="Создать комментарий"
        closeModal={() =>
          setCommentModal({
            stageId: null,
            positionId: null,
            isFullMod: true,
            isOpen: false,
            positionsIDs: [],
          })
        }
        isOpen={commentModal.isOpen}
      >
        <CommentModal
          positionsIDs={commentModal.positionsIDs}
          stageId={commentModal.stageId}
          getData={getData}
          isFullMod={commentModal.isFullMod}
          callback={closeCommentModalAndReftesh}
          positionId={commentModal.positionId}
        ></CommentModal>
      </Modal>
      <Modal
        isOpen={moveToStageModal.isOpen}
        closeModal={() =>
          setMoveToStageModal({
            positionId: null,
            isOpen: false,
          })
        }
      >
        <MoveToStageModal
          getData={getData}
          setMoveToStageModal={setMoveToStageModal}
          moveToStageModal={moveToStageModal}
        ></MoveToStageModal>
      </Modal>
    </>
  );
}

export default observer(Dashbord);
