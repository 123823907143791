export const date = {
	transform(dateString, showTime = true) {

		if(!dateString) return null;

		const addLeadingZeroes = (str) => {
			return ('0' + str).slice(-2)
		}

		const date = new Date(dateString);

		let result = addLeadingZeroes(date.getDate()) + '.' + addLeadingZeroes(date.getMonth()+1) + '.' + date.getFullYear();
		if(showTime){
			result =  result+ ' в ' + addLeadingZeroes(date.getHours()) + ':' + addLeadingZeroes(date.getMinutes())
		}

		return  result
	}
}