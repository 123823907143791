import { FaRegCopy } from "react-icons/fa";

function CopyToClipboard({ string }) {

	return (
		<div className="relative">
			<FaRegCopy
				onClick={(ev) => {
					ev.stopPropagation();
					navigator.clipboard.writeText(string);
				}}
				data-tooltip-hidden={false}
				data-tooltip-id={"cell-click-alert"}
				data-tooltip-content="Скопировано в буфер обмена"
				data-tooltip-place="top"
				data-tooltip-delay-hide={500}
				className="text-[12px] ml-[5px] text-Content/Middle flex-none hover:text-Accent/Blue hover:cursor-pointer"
			/>
		</div>

	);
}

export default CopyToClipboard;