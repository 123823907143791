import html2canvas from "html2canvas";

const downloadImage = (blob, fileName) => {
	const fakeLink = window.document.createElement("a");
	fakeLink.style = "display:none;";
	fakeLink.download = fileName;
	fakeLink.href = blob;
	document.body.appendChild(fakeLink);
	fakeLink.click();
	document.body.removeChild(fakeLink);
	fakeLink.remove();
};

const exportAsImage = async (el, imageFileName) => {
	if (!el) return
	const canvas = await html2canvas(el);
	// el.innerHTML = '';
	// el.style.width = '398px';
	// el.style.height = '252px';
	// canvas.style.width = 'auto';
	// canvas.style.height = 'auto';
	// el.appendChild(canvas);
	// const canasAfterResize = await html2canvas(el);
	const image = canvas.toDataURL("image/png", 1.0);
	downloadImage(image, imageFileName)
};

export default exportAsImage;