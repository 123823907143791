import Loader from '../../loader/loader'

function ButtonRed({ name, onPress, disabled, loading }) {

	return (
		<div
			onClick={onPress}
			className={`
				${disabled ? 'pointer-events-none bg-Dominant/Dop' : 'bg-Accent/Red_Middle'}
				inline-block py-[1.1rem] ease-out duration-300 group px-[1.8rem] hover:bg-Accent/Red  hover:cursor-pointer  font-medium rounded-[8px]  text-Content/Dark text-Regular(16_20)`}>
			<span className="relative  text-white ease-in-out duration-300 items-center ">
				{loading && <Loader></Loader>}
				<div className={`
					${loading ? 'opacity-0' : 'opacity-100'}
					flex gap-[0.8rem]
				`}>
					<span>{name}</span>
				</div>
			</span>
		</div>
	);
}

export default ButtonRed;