import Loader from '../../loader/loader'

function ButtonBorderRound({ name, onPress, disabled, loading, Icon }) {

	return (
		<div
			onClick={onPress}
			className={`
				${disabled ? 'pointer-events-none border-Dominant/Dop' : 'border-Accent/Red'}
				border border-1 justify-center flex inline-block py-[0.6rem] group px-[1.2rem]  hover:cursor-pointer  font-medium rounded-[8px]  h-[36px] flex items-center  text-Content/Dark text-Regular(14_16)`}>
			<span	 className="  relative group-hover:text-Content/Middle text-Content/Dark ease-in-out duration-300 items-center ">
				{loading && <Loader></Loader>}
				<div className={`
					${loading ? 'opacity-0' : 'opacity-100'}
					flex gap-[0.8rem]
				`}>
					<span className='flex items-center text-center'>{name}</span>
					{Icon && <Icon></Icon>}
				</div>
			</span>
		</div>
	);
}

export default ButtonBorderRound;