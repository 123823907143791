import { useEffect, useState, useRef, useCallback } from "react";


import useOrder from "../../../hooks/order.hook";
import Loader from "../../loader/loader";
import stageStore from "../../../store/stage";
import dashbordStore from "../../../store/dashbord";
import usePosition from "../../../hooks/position.hook";
import Checkbox from '../../ui/checkbox/checkbox'
import ButtonBorderRound from "../../ui/buttonBorderRound/buttonBorderRoundMobile";
import ButtonResolve from "../../ui/buttonResolve/buttonResolveMobile";
import ButtonReject from "../../ui/buttonReject/buttonRejectMobile";
import ButtonGrey from "../../ui/buttonGrey/buttonGreyMobile";
import Modal from "../../modal/modal";
import ConfirmModal from "../confirmModal/confirmModal-mobile";
import OrderDetail from "./orderDetail";
import OrderStages from "./orderStages-mobile";
import ActionDropdown from '../../actionDropdown/actionDropdown'
import { observer } from "mobx-react-lite";
import './style.css'
import {useDebouncedFunction} from "../../../hooks/debounce.hook";

function OrderModal({ orderId, onPositionDetail }) {

	const intervalId = useRef(null);
	const { getOrder } = useOrder();
	const { setPositionsToUrgent, setPositionsToCanceled,setPositionsToDelivered,setStatus, setPositionsToClosed } = usePosition();
	const { selectedPositionsStages } = dashbordStore;
	const [info, setInfo] = useState(false);
	const [completeDelivery, setCompleteDelivery] = useState(false);
	const { stages, currentStage:{statuses} } = stageStore;
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [isUrgent, setIsUrgent] = useState(false);
	const [isModalApproveOpened, setIsModalApproveOpened] = useState(false);
	const [isModalActionsOpened, setIsModalActionsOpened] = useState(false);
	const [tab, setTab] = useState(1);

	const getData = useCallback(async () => {


		if (!orderId) return;

		setLoading(true);


		const positionOptions = tab === 1 ? {
			drawings: true,
			c_position_stages: {
				filters: {
					isCurrentStage: true
				},
				populate: {
					stage: true,
					status: false,
				}
			}
		} :
			{
				drawings: {
					populate: {
						status: true,
						user:true
					}
				},
				c_position_stages: {
					populate: {
						stage: true,
						status: true,
						user: true,
						comments: true,
					}
				}
			}

		const { data } = await getOrder({
			id: orderId,
			positionOptions
		})


		if (data.positions.filter(x => x.c_position_stages.find(x => x.isCurrentStage)?.stage.step !== 9).length) {
			setCompleteDelivery(false)
		} else {
			setCompleteDelivery(true)
		}

		data.positions.forEach(x => {
			const positonStages = stages.reduce((acc, curr, index) => {
				let positionStageItem;
				const currentStatusStep = curr.step
				let isStageItemAdded = false
				for (let i = 0; i < x.c_position_stages.length; i++) {
					const isLastIteration = i === x.c_position_stages.length - 1
					if(currentStatusStep === x.c_position_stages[i].stage.step && !isStageItemAdded) {
						positionStageItem = {
							...curr,
							item: x.c_position_stages[i]
						}
						acc.push(positionStageItem)
						isStageItemAdded = true
					}
					if(isLastIteration && !isStageItemAdded) {
						positionStageItem = {
							...curr,
							item: undefined
						}
						acc.push(positionStageItem)
					}
				}
				return acc;
			}, [])
			x.stages = positonStages;
		})
		setData(data);
		setIsUrgent(data.isUrgent);
		setLoading(false);

	}, [getOrder, orderId, stages, tab])

	const {func: debouncedGetData, timeoutId: getDataTimeoutId} = useDebouncedFunction(() => getData(), 3000)



	const onUrgent = async () => {
		setIsUrgent(!isUrgent);
	}

	const onCloseOrder = async () => {
		const ids = data.positions.map(x => x.id);
		setIsModalApproveOpened(false);
		setIsModalActionsOpened(false);
		await setPositionsToClosed(ids);
		getData();
	}

	const onCancelOrder = async () => {
		const ids = data.positions.map(x => x.id);
		setIsModalApproveOpened(false);
		setIsModalActionsOpened(false);
		await setPositionsToCanceled(ids);
		getData();
	}

	const onOrderDeliveryComplete = async () => {
		const ids = data.positions.map(x => x.id);
		setIsModalApproveOpened(false);
		setIsModalActionsOpened(false);
		await setPositionsToDelivered(ids);
		getData();
	}

	const onChangeStatusOrder = async (type) => {

		const {id}  = statuses.find(x => x[type]);
		let ids;

		if(tab === 1){
			ids = [...data.positions.filter(x => x.c_position_stages[0].stage.step == 9)].map(x=> x.c_position_stages[0].id);
		}else{
			const cPositionStages =[...data.positions.map(x=>x.c_position_stages)];
			ids = cPositionStages.map(x => x.find(x=> x.isCurrentStage && x.stage.step === 9)?.id);
		}
		

		for(const positionStageId of ids){
			await setStatus(positionStageId, id)
		}
		setIsModalApproveOpened(false);
		getData(true);
	}


	const selectAllPositions = () => {
		if (selectedPositionsStages.length === data.positions.length) {
			return dashbordStore.addPositions([])
		}
		const ids = data.positions.map(x => ({
			position_id: x.id
		}))
		dashbordStore.addPositions(ids)
	}



	const onSelectPosition = (id) => {
		dashbordStore.addPosition(
			{
				id: null,
				position_id: id
			}
		)
	}


	useEffect(() => {
		if (data?.isUrgent === isUrgent || !intervalId.current) return;
		if (data?.positions) {
			(async () => {
				const positionsIds = data.positions.map(x => x.id)
				await setPositionsToUrgent({
					ids: positionsIds,
					isUrgent
				});
			})();
		}
	}, [isUrgent])

	useEffect(() => {
		dashbordStore.clearSelected();
		getData()
		intervalId.current = setInterval(getData, 20000);
		return () => {
			clearInterval(intervalId.current)
		}
	}, [getData])

	const managerComment = data?.localComment?.comment || data?.managerComment;

	return (
		<div className="">
			{loading && !data && <Loader></Loader>}
			{data &&
				<>
					<div className="">
						<div className="grid grid-cols-2 gap-[2.6rem] mb-[1.8rem]">
							<div className="flex gap-[2rem] items-center">
								<div className="flex gap-[0.5rem]">
									<span className="block text-Regular(16_20)">Статус заказа:</span>
									<span className="block text-Regular(16_20) font-bold">{data.order_status?.name}</span>
								</div>


							</div>

							<div className="flex items-center justify-end">

								<Checkbox
									onChange={onUrgent}
									label="Срочный заказ"
									className="text-Accent/Red font-medium"
									active={isUrgent}
								></Checkbox>
							</div>
						</div>
						<div className="mb-[2rem] border">
							<div onClick={() => setInfo(!info)} className="flex items-center justify-between px-[1.8rem] py-[1rem] text-Regular(16_20) border border-t-0 border-l-0 border-r-0">
								<span>
									Информация о заказе
								</span>
								<i className={`${info && 'rotate-180'} icon-arrow text-[12px] `}></i>
							</div>
							{info &&
								<div className="p-[1.8rem] flex flex-col gap-[1.8rem]">
									<div className="flex flex-col gap-[0.8rem]">
										<div className="flex gap-[0.5rem]">
											<span className="block text-Regular(16_20)">Заказ:</span>
											<span className="block text-Regular(16_20) font-bold">№{data.orderId || '-'}</span>
										</div>
										<div className="flex gap-[0.5rem]">
											<span className="block text-Regular(16_20)">Дата заказа:</span>
											<span className="block text-Regular(16_20) font-bold">{data.date || '-'}</span>
										</div>
										<div className="flex gap-[0.5rem]">
											<span className="block text-Regular(16_20)">Менеджер:</span>
											<span className="block text-Regular(16_20) font-bold">{data.manager || '-'}</span>
										</div>
									</div>
									<div className="flex flex-col gap-[0.8rem]">
										<div className="flex gap-[0.5rem]">
											<div className="text-Regular(16_20)">Заказчик:</div>
											<div className="text-Regular(16_20) font-bold">{data.company || '-'}</div>
										</div>
										<div className="flex gap-[0.5rem]">
											<div className="text-Regular(16_20)">ФИО клиента:</div>
											<div className="text-Regular(16_20) font-bold">{data.userinfo?.name + ' ' + data.userinfo?.surname}</div>
										</div>
										<div className="flex gap-[0.5rem]">
											<div className="text-Regular(16_20)">EMAIL клиента:</div>
											<div className="text-Regular(16_20) font-bold">{data.userinfo?.email || '-'}</div>
										</div>
										<div className="flex gap-[0.5rem]">
											<div className="text-Regular(16_20)">Телефон клиента:</div>
											<div className="text-Regular(16_20) font-bold">{data.userinfo?.phone || '-'}</div>
										</div>
									</div>
									<div className="flex gap-[0.5rem]">
										<div className="text-Regular(16_20)">Доставка:</div>
										<div className="text-Regular(16_20) font-bold">
											<span>{data.deliveryinfo?.name}</span>
											{data.deliveryinfo?.descr && <span> ({data.deliveryinfo?.descr})</span>}
										</div>
									</div>
									<div className="flex gap-[0.5rem]">
										<div className="text-Regular(16_20)">Комментарий:</div>
										<div className="text-Regular(16_20) font-bold">
											<span>{data.comment}</span>
										</div>
									</div>
								</div>
							}

						</div>

					</div>


					<div className="">
					{!!managerComment && (
                <div className="  gap-[1rem] mb-[2rem] bg-Accent/Light_Yellow  text-Regular(14_16) px-[1rem] py-[2px] leading-1">
                  <span className="font-medium">
                    Комментарий администратора к заказу:
                  </span>
                  <span> {managerComment}</span>
                </div>
              )}
						<div className="flex text-Regular(16_18)">
							<div
								onClick={() => setTab(1)}
								className={`${tab === 1 ? 'before:absolute before:left-0 before:right-0 before:top-0 before:h-[2px] before:bg-Accent/Yellow  border-Content/Border pointer-events-none' : 'border-[transparent] text-Content/Middle'} border  font-semibold relative ease-in-out duration-300 hover:cursor-pointer hover:bg-Dominant/Dop hover:text-Content/Middle py-[1.2rem] w-[20rem] text-center border-b-0 `}>Детали заказа</div>
							<div
								onClick={() => setTab(2)}
								className={`${tab === 2 ? ' before:absolute before:left-0 before:right-0 before:top-0 before:h-[2px] before:bg-Accent/Yellow  border-Content/Border pointer-events-none' : 'text-Content/Middle border-[transparent]'} relative  font-semibold border ml-[-1px] ease-in-out duration-300 hover:cursor-pointer hover:bg-Dominant/Dop hover:text-Content/Middle py-[1.2rem] w-[20rem] text-center  border-b-0 `}>Движение по этапам</div>
						</div>

						<div className="overflow-auto  pb-[3rem] mr-[-18px]">

							{tab == 1 ?
								<OrderDetail
									selectedPositionsStages={selectedPositionsStages}
									onSelectPosition={onSelectPosition}
									selectAllPositions={selectAllPositions}
									onPositionDetail={onPositionDetail}
									data={data}
									getData={getData}
								>
								</OrderDetail> :
								<OrderStages
									selectedPositionsStages={selectedPositionsStages}
									onSelectPosition={onSelectPosition}
									selectAllPositions={selectAllPositions}
									onPositionDetail={onPositionDetail}
									data={data}
									getData={getData}
									debouncedGetData={debouncedGetData}
								></OrderStages>}
						</div>

					</div>
				</>
			}
			{data &&
				<div className="sticky shadow-default p-[17px] min-h-[78px] ml-[-18px] mr-[-18px] bg-[#fff] bottom-0 flex gap-[36px] items-center justify-between">
					{selectedPositionsStages.length ? <ActionDropdown
						positions={true}
					></ActionDropdown>
					: <div></div> }
					<ButtonGrey
						onPress={() => setIsModalActionsOpened(true)}
						name="Действия с заказом"
					>
					</ButtonGrey>
				</div>
			}
			<Modal
				isOpen={isModalActionsOpened}
				closeModal={() => setIsModalActionsOpened(false)}
			>
				<div className="flex flex-col gap-[2.6rem] p-[70px] rounded-[8px]">
					<div className="flex flex-col gap-[1.5rem]">
						{
							completeDelivery &&
							<ButtonResolve
							onPress={() => setIsModalApproveOpened({
								message: `Вы уверены, что хотите проставить статус "Не доставлен" для заказа ?`,
								callback: onOrderDeliveryComplete
							})}
								name="Выполнить заказ"
							></ButtonResolve>
						}
						<ButtonReject onPress={() => setIsModalApproveOpened({
							message: `Вы уверены, что хотите проставить статус "Не доставлен" для заказа ?`,
							callback: () => onChangeStatusOrder('deliveryNotDelivered'),
						})} name="Не доставлен"></ButtonReject>
					</div>
					<div className="flex flex-col gap-[1.5rem] pt-[20px] border border-Content/Light border-l-0 border-r-0 border-b-0">
						<ButtonGrey
							onPress={() => setIsModalApproveOpened({
								message: 'Вы уверены, что хотите отменить весь заказ ?',
								callback: onCancelOrder
							})}
							name="Отменить весь заказ"
						>
						</ButtonGrey>
						<ButtonBorderRound
							onPress={() => setIsModalApproveOpened({
								message: 'Вы уверены, что хотите закрыть весь заказ ?',
								callback: onCloseOrder
							})}
							name="Закрыть весь заказ"
						></ButtonBorderRound>
						<ButtonBorderRound 
							onPress={() => setIsModalApproveOpened({
							message: `Вы уверены, что хотите проставить статус "В пути" для заказа ?`,
							callback: () => onChangeStatusOrder('deliveryOnTheWay'),
						})} name="В пути"></ButtonBorderRound>

					</div>



				</div>
			</Modal>
			<Modal
				isOpen={isModalApproveOpened}
				closeModal={() => setIsModalApproveOpened(false)}
			>
				<ConfirmModal
					title={isModalApproveOpened.message}
					onResolve={isModalApproveOpened.callback}
					onReject={() => setIsModalApproveOpened(false)}
				>
				</ConfirmModal>
			</Modal>
		</div >

	);
}

export default OrderModal;